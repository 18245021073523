<template>
  <div>
    <div class="service-main">
    <div class="container">
        <div class="service-inner-main">
            <div class="service-main-left">
                <h1>Services we offer</h1>
                <p>Our services are all fine tuned to our customers needs. We ensure that we serve the best affordably and uniquely.</p>
            </div>
            <div class="service-main-right">
                <div class="service-right-box">
                    <div class="service-box-img">
                        <img src="../assets/images/local.png">
                    </div>
                    <div class="service-box-detail">
                        <h2>Local and over the road truckloads.</h2>
                        <p>We provide dry vans/reefers and trucks for short/long distance deliveries.</p>
                    </div>
                </div>
                <div class="service-right-box">
                    <div class="service-box-img">
                        <img src="../assets/images/search.png">
                    </div>
                    <div class="service-box-detail">
                        <h2>Power only Trucking</h2>
                        <p>We can provide a truck and driver to move your trailers. We provide the power unit and the best driver, while you provide the trailers (dry van or reefers).</p>
                    </div>
                </div>
                <div class="service-right-box">
                    <div class="service-box-img">
                        <img src="../assets/images/search.png">
                    </div>
                    <div class="service-box-detail">
                        <h2>Fleet Management</h2>
                        <p>Our team of professionals can hire and manage a team of drivers to operate your branded tractors to accommodate your logistic needs.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="service-detail">
    <div class="container">
        <div class="service-data">
            <div class="service-box-in">
                <h3>CALL CENTRE</h3>
                <ul>
                    <li>Give us a free call</li>
                    <li><a href="tel:+1 (317) 536-8005" class="c-f">+1 (317) 536-8005</a></li>
                    <li>By contacting us, you agree to receive informational text messages. You may opt out of receiving text messages (SMS) at any time by replying to STOP to any message.</li>
                </ul>
            </div>
            <div class="service-box-in">
                <h3>WORKING HOURS</h3>
                <ul>
                    <li>MON - SUN : 9:00AM - 6:00PM</li>
                    <li><i>Emergency can call helpline</i></li>
                </ul>
            </div>
            <div class="service-box-in">
                <h3>LOCATION</h3>
                <ul>
                    <li class="c-f">2800 Bluff Rd</li>
                    <li class="c-f">Indianapolis, IN, 46225</li>
                </ul>
            </div>
            <!-- <div class="service-box-in navigation-button">
                <a @click="ShowModal()">Request quote</a>
            </div> -->
        </div>
    </div>
</div>
  </div>
</template>
<script>
export default {
  name: 'Service',
  methods: {
      ShowModal() {
          this.$modal.show('QuoteRequestModal')
      }
  }
}
</script>